import _ from 'lodash';
import moment from 'moment';

import store from './store/index';

import {RESET_ALL} from './actions/actionTypes';
import {
  setBasketStates,
  setCookingStates,
  setShelfStates,
  setMQTTConnection,
  setDiagnostics,
  setFoods,
  setCameraID,
  setBasketList,
  setGetGripperSensor,
  setGetDispenserLockSensor,
  setGetElevatorSensor,
  setHomeJoints,
  setHopperState,
  setElevatorState,
  setFryersState,
  setRelayState,
  setNotificationState,
  setInRackStates,
  setReadyNextRackStates,
  setRobotArmState,
  setRosHeaderTimeStamp,
  setScannerState,
  setUIState,
  setVersionNumber,
  setAWSAvailable,
  setRosRunning,
  setDisabledFryerSensorsArray,
  setNumFryerSensors,
  setRushModeFlag,
  setBITState,
  setSafetyControllerStatus,
  setSmartChefRunning,
} from './actions/index';
import {DIAGNOSTICS_NODES, PNEUM_CHANNEL} from './enums';
// TODO(NK): Add cookTime changing
// import {cookTime} from './cook_times';
import {SET_IDLE_UI, toggleLoadingPowerState} from './actions/uiActions';
import {addAlarmFields, getAwsAccountObj} from './util';
const {build_mqtt_connection} = require('./aws_iot/iot_engine');
const {MQTTRequest, MQTTRequestType} = require('./aws_iot/thing_mqtt_request');
const {ThingMQTTAccessPoint} = require('./aws_iot/thing_mqtt');
const {FlippyPowerMQTTAcessPoint} = require('./aws_iot/flippy_power');

// Public ROS services that can be called
export let abortTrajectory = {};
export let abortBehavior = {};
export let fanucPneum = {};
export let controlGripperActionConfig = {};
export let robotReboot = {};
export let robotReset = {};
export let getAllTempLogs = {};
export let gogoRoboEff = {};
export let gogoRoboJoints = {};
export let goToNamedJoints = {};
export let logAlarmService = {};
export let setTargetMode = {};
export let placeOrder = {};
export let swapTool = {};
export let updateOffset = {};
export let revertOffset = {};
export let getGripperState = {};
export let toggleFlag = {};
export let runFryerPoseCalibration = {};
export let disableFryerSensor = {};
export let setRelayController = {};

export let updateTicket = {};
export let disableSlot = {};
export let jogElevator = {};
export let requestHelp = {};
export let resetElevator = {};
export let elevatorManual = {};

// TODO(NK): reconfigure cook times

// Public ROS topics to publish to
export let connected = {};

export let getGripperSensorParam = {};
export let getDispenserLockSensorParam = {};
export let getElevatorSensorParam = {};
export let regularSlotBasketIdMapping = {};
export let getDisabledFryerSensors = {};
export let getNumFryerSensors = {};
export let rushModeFlag = {};

export let mqtt_ap, flippy_power_ap;
export let USER_ID;
export const MQTT_ACK_REQUIRED = true;

// How frequently ROS will try to reconnect upon disconnect in ms.
const UI_TIMEOUT = 8000;
// How often the last message is checked in ms.
const MESSAGE_CHECK = 5000;

let DEFAULT_USER_ID = 'UNKNOWN';
var mqttConnection;
var mqtt_connected;
var continueChecking;
let lastMsgMoment;

// Time after which slot will be red if basket is not seen in ms.
// const MAX_VISIBLE_TIME = 30 * 1000;

// NOTE(JRA): WARNING: Has side-effects!!!
const slotDifference = function (currentSlotStates, prevSlotStates) {
  let diff = false;
  if (prevSlotStates && prevSlotStates.length === currentSlotStates.length) {
    for (let i = 0; i < currentSlotStates.length; ++i) {
      if (_.isEqual(currentSlotStates[i], prevSlotStates[i])) {
        // This is needed because it keeps the references the same so that
        // Redux won't detect a difference (because the objects are not equal
        // via the algorithm they use).
        currentSlotStates[i] = prevSlotStates[i];
      } else {
        diff = true;
      }
    }
  } else {
    diff = true;
  }
  return diff;
};

// Returns the payload we just used to update the store's state.
const slotUpdateDispatch = function (
  rosHost,
  newStates,
  prevStates,
  buildDispatchFunc
) {
  const cookingSlotStateDiff = slotDifference(newStates, prevStates);
  if (cookingSlotStateDiff) {
    store.dispatch(buildDispatchFunc(rosHost, newStates));
  }
  return newStates;
};

// -------------------ROS Init Steps------------------------

export async function close_ros(history) {
  if (mqtt_connected) {
    if (mqtt_ap) {
      // NOTE(ST): Removing await causes exception during disconnection
      // and keeping it actually does not wait for the function
      // to finish executing. However, all the unsubscriptions would
      // finish before reaching the disconnection clause.
      await mqtt_ap.clearIntervals();
    }
    console.log(`Closing MQTT connection...`);
    history.push('/');
    await mqttConnection.disconnect().catch((error) => {
      console.log('Disconnect error: ' + error);
    });
  }
}

export async function clear_ros() {
  continueChecking = false;
  if (mqtt_ap) {
    await mqtt_ap.clearIntervals();
  }
}

export async function reset_ros(rosHost) {
  store.dispatch({
    type: RESET_ALL,
    host: rosHost,
  });

  if (mqttConnection) {
    mqtt_connected = true;
    continueChecking = false;
    store.dispatch(setMQTTConnection(rosHost, true));
    register_ros(rosHost);
  }
}

export async function init_ros(ros_thing_name, rosHost, user_email) {
  USER_ID = user_email ? user_email : DEFAULT_USER_ID;
  let user = USER_ID;
  let now_ts = Date.now();
  continueChecking = false;

  console.log(`Thing_name ${ros_thing_name}, user: ${user}`);

  // MQTT client connection callbacks
  const on_interrupt_callback = function (code) {
    mqtt_connected = false;
    continueChecking = false;
    connected[rosHost] = false;
    console.log(
      `AWS IoT Core connection interrupted (${ros_thing_name}) code: ${code}.`
    );
    store.dispatch(setMQTTConnection(rosHost, false));
    store.dispatch({
      type: RESET_ALL,
      host: rosHost,
    });
  };
  const on_resume_callback = function (return_code, session_present) {
    console.log(
      `AWS IoT Core connection resumed: rc: ${return_code} existing session: ${session_present}`
    );
    setTimeout(async function () {
      await clear_ros();
      await reset_ros(rosHost);
    }, UI_TIMEOUT);
  };
  const on_disconnect_callback = function () {
    mqtt_connected = false;
    continueChecking = false;
    connected[rosHost] = false;
    console.log(`AWS IoT Core connection disconnected.`);
    store.dispatch(setMQTTConnection(rosHost, false));
    store.dispatch({
      type: RESET_ALL,
      host: rosHost,
    });
  };

  // building mqtt connection
  const client_id = [
    'MSCInstance',
    getAwsAccountObj(rosHost).name,
    user,
    Math.floor(Math.random() * 100000000),
  ].join('_');
  await build_mqtt_connection(
    getAwsAccountObj(rosHost),
    on_interrupt_callback,
    on_resume_callback,
    on_disconnect_callback,
    client_id
  )
    .then((connection) => {
      mqttConnection = connection;
      mqtt_connected = true;
      store.dispatch(setMQTTConnection(rosHost, true));
    })
    .catch((error) => {
      mqtt_connected = false;
      console.log(`Error while connecting: ${error}`);
    });
  if (!mqtt_connected) {
    setTimeout(() => {
      window.alert(`Connecting to AWS IoT failed. Abort!`);
    }, 1);
    store.dispatch({
      type: RESET_ALL,
      host: rosHost,
    });
    return;
  }

  let ros_product = 'flippy';
  mqtt_ap = new ThingMQTTAccessPoint(
    mqttConnection,
    ros_thing_name,
    ros_product,
    user,
    (Date.now() - now_ts) / 1000 // elapsed time for building mqtt connection
  );
  flippy_power_ap = new FlippyPowerMQTTAcessPoint(
    mqttConnection,
    ros_thing_name,
    ros_product,
    user
  );

  // --- reset ros_subscriptions if no messages received ---
  const checkMsg = async () => {
    if (continueChecking) {
      if (moment().diff(lastMsgMoment) > MESSAGE_CHECK) {
        console.log(
          `Resetting UI because no new messages received (${rosHost}).`
        );
        store.dispatch({
          type: SET_IDLE_UI,
          host: rosHost,
          payload: {
            isIdle: true,
            message:
              'The state of the robot may be out of date. Please wait a few moments or contact Miso Support if the problems persists.',
          },
        });
        store.dispatch(setRosRunning(rosHost, false));
        store.dispatch(setBITState(rosHost, false));
        store.dispatch(
          toggleLoadingPowerState({
            status: false,
            text: '',
          })
        );
        store.dispatch(setSmartChefRunning(rosHost, false));
        clear_ros();
        reset_ros(rosHost);
      } else {
        store.dispatch({
          type: SET_IDLE_UI,
          host: rosHost,
          payload: {
            isIdle: false,
          },
        });
      }
    }
    setTimeout(checkMsg, MESSAGE_CHECK);
  };
  setTimeout(checkMsg, MESSAGE_CHECK);

  register_ros(rosHost);
}

export async function register_ros(rosHost) {
  let user = USER_ID;
  let ack_error = false;

  // subsribe to MQTT Ack topic first
  await mqtt_ap.subscribe_to_ack();
  // reset startup timestamp
  mqtt_ap.startup_ts = Date.now();

  const diagnosticListener = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SUBSCRIBE,
    ros_topic: '/diagnostics_agg',
    type: 'diagnostic_msgs/DiagnosticArray',
    data: {},
    user: USER_ID,
  });

  const diagnosticListenerCallback = async (msg) => {
    let diagnostic_msgs = {};
    // TODO (WL): Update this when we switch to checking ROS connection
    store.dispatch(setRosRunning(rosHost, true));
    msg.status.forEach((diag) => {
      const {name, values, level} = diag;
      if (DIAGNOSTICS_NODES.includes(name)) {
        if (name.includes('/network_dev_monitor/')) {
          diagnostic_msgs[name] = level;
        } else {
          if (name.substring(1) in diagnostic_msgs) {
            var all_objs = diagnostic_msgs[name.substring(1)].concat(values);
            const key = 'key';
            const arrayUniqueByKey = [
              ...new Map(all_objs.map((item) => [item[key], item])).values(),
            ];

            diagnostic_msgs[name.substring(1)] = arrayUniqueByKey;
          } else {
            diagnostic_msgs[name.substring(1)] = values;
          }
        }
      }
    });
    store.dispatch(setDiagnostics(rosHost, diagnostic_msgs));
  };

  mqtt_ap
    .submit_request(diagnosticListener, diagnosticListenerCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  // Each topic will reset to the initial state after this many
  // milliseconds of not receiving a message.
  lastMsgMoment = moment();

  // flippyListener - SUBSCRIBE
  var flippyListener = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SUBSCRIBE,
    ros_topic: '/smart_chef/ui_status',
    type: 'smart_chef_msgs/UIStatus',
    data: {},
    user: user,
  });

  let prevBasketSlotStates = null;
  let prevCookingSlotStates = null;
  let prevShelfSlotStates = null;
  let prevInRackSlotStates = null;
  let prevReadyNextSlotStates = null;

  let prevUIState = null;
  let prevHopperState = null;
  let prevElevatorState = null;
  let prevFryerState = null;
  let prevRelayState = null;
  let prevNotificationState = null;
  let prevSensors = null;
  let prevRosHeaderTimeStamp = null;

  const flippyListenerCallback = async (msg) => {
    lastMsgMoment = moment();

    // NOTE(ST): We can infer that Flippy is on once we
    // we receive messages on this topic.
    store.dispatch(setSmartChefRunning(rosHost, true));
    continueChecking = true;

    if (!_.isEqual(prevRosHeaderTimeStamp, msg.header)) {
      store.dispatch(setRosHeaderTimeStamp(rosHost, msg.header.stamp));
      prevRosHeaderTimeStamp = msg.header.stamp;
    }

    if (!_.isEqual(prevUIState, msg.state)) {
      store.dispatch(setUIState(rosHost, msg.state));
      prevUIState = msg.state;
    }

    if (!_.isEqual(prevHopperState, msg.dispenser)) {
      store.dispatch(setHopperState(rosHost, msg.dispenser));
      prevHopperState = msg.dispenser;
    }

    if (!_.isEqual(prevElevatorState, msg.elevator)) {
      store.dispatch(setElevatorState(rosHost, msg.elevator));
      prevElevatorState = msg.elevator;
    }

    if (!_.isEqual(prevFryerState, msg.fryers)) {
      store.dispatch(setFryersState(rosHost, msg.fryers));
      prevFryerState = msg.fryers;
    }

    if (!_.isEqual(prevRelayState, msg.relays)) {
      store.dispatch(setRelayState(rosHost, msg.relays));
      prevRelayState = msg.relays;
    }

    const sortNotificationsOrder = (data) => {
      const order = {
        '/agent/robot_arm': 1,
        '/agent/elevator': 2,
        '/': 3,
      };

      return data.sort((a, b) => {
        const priorityA = order[a.component_path] || 4; // Assign a default high value if not found
        const priorityB = order[b.component_path] || 4;
        return priorityA - priorityB;
      });
    };

    if (!_.isEqual(prevNotificationState, msg.notifications)) {
      let filteredNotificationsState = sortNotificationsOrder(
        msg.notifications.filter(
          (item, index, self) =>
            // Exclude specific 'code' values first
            ![
              'e_stopped',
              'drawer_open',
              'robot_occlusion',
              'light_curtain_tripped',
              'light_curtain_robot_stop',
              'light_curtain_left_tripped',
            ].includes(item.code) &&
            // Check if the current item is the first occurrence of this component_path and code
            index ===
              self.findIndex(
                (t) =>
                  t.component_path === item.component_path &&
                  t.code === item.code
              )
        )
      );
      store.dispatch(setNotificationState(rosHost, filteredNotificationsState));
      prevNotificationState = filteredNotificationsState;
    }

    if (!_.isEqual(prevSensors, msg.sensors)) {
      const filteredScannerState = msg.sensors
        .filter(
          (sensor) =>
            ((sensor.safety_critical &&
              sensor.sensor_name !== 'stop_commanded' &&
              sensor.sensor_name !== 'left_door' &&
              sensor.sensor_name !== 'right_door') ||
              sensor.sensor_name === 'light_curtain') &&
            sensor.value === true
        )
        .map((sensor) => sensor.value_description);
      store.dispatch(setScannerState(rosHost, filteredScannerState));
      prevSensors = filteredScannerState;
    }

    const transformAutoBasketSlotsMsg = (slotsMsg) => {
      let transformedSlots = [];
      slotsMsg.forEach((slot, index) => {
        transformedSlots.push({
          basket_id: slot.basket_id,
          slot_path: slot.slot_path,
          disabled: slot.disabled,
          ticket: slot.ticket,
          requires_manual_classification: slot.requires_manual_classification,
          request_pick_up: slot.request_pick_up,
        });
      });

      return transformedSlots;
    };

    // Note(JS): Iterate through assigned, waiting and pending tickets.
    const transformOutputSlotMsg = (slotsMsg) => {
      let transformedOutputSlots = [];
      slotsMsg.in_progress_tickets.forEach((slot) => {
        transformedOutputSlots.push({
          uuid: slot.uuid,
          food_type: slot.food_type,
          portion_size: slot.portion_size,
          doneness: slot.doneness,
          return_strategy: slot.return_strategy,
          priority: slot.priority,
          order_time: slot.order_time,
          has_submerge: slot.has_submerge,
          submerge_time: slot.submerge_time,
          has_unsubmerge: slot.has_unsubmerge,
          unsubmerge_time: slot.unsubmerge_time,
          delivery_time_type: slot.delivery_time_type,
          delivery_time: slot.delivery_time,
        });
      });
      return transformedOutputSlots;
    };

    const transformSlotMsg = (slotsMsg) => {
      let transformedSlots = [];
      slotsMsg.forEach((slot) => {
        transformedSlots.push({
          basket_id: slot.basket_id,
          slot_path: slot.slot_path,
          disabled: slot.disabled,
          ticket: slot.ticket,
          disable_reason: slot.disable_reason,
          disable_user: slot.disable_user,
          disable_permissions: slot.disable_permissions,
          disable_time: slot.disable_time,
        });
      });
      return transformedSlots;
    };

    const transformBasketMsg = (slotsMsg) => {
      let transformedSlots = [];
      slotsMsg.forEach((slot) => {
        transformedSlots.push({
          basket_id: slot.basket_id,
          slot_path: slot.basket_path,
          disabled: slot.disabled,
          disable_reason: slot.disable_reason,
          disable_user: slot.disable_user,
          disable_permissions: slot.disable_permissions,
          disable_time: slot.disable_time,
        });
      });
      return transformedSlots;
    };

    // UPDATING STORE --------------------------------------------------------
    // We need to store each previous state so that we don't needlessly update
    // on every message that we get sent.
    prevCookingSlotStates = slotUpdateDispatch(
      rosHost,
      transformSlotMsg(msg.fryer_slots),
      prevCookingSlotStates,
      setCookingStates
    );
    prevShelfSlotStates = slotUpdateDispatch(
      rosHost,
      transformSlotMsg(msg.hanger_slots),
      prevShelfSlotStates,
      setShelfStates
    );
    prevInRackSlotStates = slotUpdateDispatch(
      rosHost,
      transformAutoBasketSlotsMsg(msg.autobasket_slots),
      prevInRackSlotStates,
      setInRackStates
    );
    prevReadyNextSlotStates = slotUpdateDispatch(
      rosHost,
      transformOutputSlotMsg(msg.ticket_queue),
      prevReadyNextSlotStates,
      setReadyNextRackStates
    );
    prevBasketSlotStates = slotUpdateDispatch(
      rosHost,
      transformBasketMsg(msg.baskets),
      prevBasketSlotStates,
      setBasketStates
    );
  };

  mqtt_ap
    .submit_request(flippyListener, flippyListenerCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  const robotArmStateListener = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SUBSCRIBE,
    ros_topic: '/robot_arm/robot_state',
    type: 'miso_robot_msgs/RobotState',
    data: {},
    user: user,
  });

  const robotArmStateListenerCallback = async (robot_arm_state_msg) => {
    const robot_state = robot_arm_state_msg.motion_state;
    store.dispatch(setRobotArmState(rosHost, robot_state));
  };

  mqtt_ap
    .submit_request(robotArmStateListener, robotArmStateListenerCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  const bitListener = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SUBSCRIBE,
    ros_topic: '/bit_running',
    type: 'std_msgs/Bool',
    data: {},
    user: user,
  });

  let prevBITState = null;

  const bitListenerCallback = async (bit_msg) => {
    if (!_.isEqual(prevBITState, bit_msg.data)) {
      store.dispatch(setBITState(rosHost, bit_msg.data));
      prevBITState = bit_msg.data;
    }
  };

  mqtt_ap.submit_request(bitListener, bitListenerCallback).catch((error) => {
    console.log('Submit_Request error: ' + error);
  });

  const safetyControllerStatusListener = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SUBSCRIBE,
    ros_topic: '/safety_controller/status',
    type: 'miso_state_msgs/SensorStatusArray',
    data: {},
    user: user,
  });

  let prevSafetyControllerStatus = null;

  const safetyControllerStatusListenerCallback = async (
    safety_controller_status
  ) => {
    if (
      !_.isEqual(prevSafetyControllerStatus, safety_controller_status.sensors)
    ) {
      store.dispatch(
        setSafetyControllerStatus(rosHost, safety_controller_status.sensors)
      );
      prevSafetyControllerStatus = safety_controller_status.sensors;
    }
  };

  mqtt_ap
    .submit_request(
      safetyControllerStatusListener,
      safetyControllerStatusListenerCallback
    )
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  // Get list of current foods
  const classesParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/smart_chef/config/menu/cook_ranges',
    type: '',
    data: {},
    user: user,
  });
  const classesParamCallback = (foods) => {
    if (foods) {
      store.dispatch(setFoods(rosHost, foods));
    }
  };
  mqtt_ap.submit_request(classesParam, classesParamCallback).catch((error) => {
    console.log('Submit_Request error: ' + error);
  });

  // Get list of support camera ids
  const supportCameraIDParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/install/support_camera_ids',
    type: '',
    data: {},
    user: user,
  });
  const supportCameraIDParamCallback = (cameraID) => {
    if (cameraID) {
      store.dispatch(setCameraID(rosHost, cameraID));
    }
  };
  mqtt_ap
    .submit_request(supportCameraIDParam, supportCameraIDParamCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  const basketParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/install/environment/baskets/',
    type: '',
    data: {},
    user: user,
  });
  const basketParamCallback = (foods) => {
    if (foods) {
      store.dispatch(setBasketList(rosHost, foods));
    }
  };
  mqtt_ap.submit_request(basketParam, basketParamCallback).catch((error) => {
    console.log('Submit_Request error: ' + error);
  });

  const homeJoints = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/install/behaviors/home_joints',
    type: '',
    data: {},
    user: user,
  });
  const homeJointsCallback = (joints) => {
    const _joints = joints.value !== 'None' ? joints.value : [];
    store.dispatch(setHomeJoints(rosHost, _joints));
  };
  mqtt_ap.submit_request(homeJoints, homeJointsCallback).catch((error) => {
    console.log('Submit_Request error: ' + error);
  });

  getGripperSensorParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/install/behaviors/enable_gripper_sensor',
    type: '',
    data: {},
    user: user,
  });
  const getGripperSensorParamCallback = (gripperSensor) => {
    let val = gripperSensor.value === 'True' || gripperSensor.value === 'true';
    return store.dispatch(setGetGripperSensor(rosHost, val));
  };
  mqtt_ap
    .submit_request(getGripperSensorParam, getGripperSensorParamCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  getDispenserLockSensorParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/smart_chef/enable_dispenser_lock',
    type: '',
    data: {},
    user: user,
  });
  const getDispenserLockSensorParamCallback = (dispenserLockSensor) => {
    let val =
      dispenserLockSensor.value === 'True' ||
      dispenserLockSensor.value === 'true';
    return store.dispatch(setGetDispenserLockSensor(rosHost, val));
  };
  mqtt_ap
    .submit_request(
      getDispenserLockSensorParam,
      getDispenserLockSensorParamCallback
    )
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  getElevatorSensorParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/elevator/enable_sensors',
    type: '',
    data: {},
    user: user,
  });
  const getElevatorSensorParamCallback = (elevatorSensor) => {
    let val =
      elevatorSensor.value === 'True' || elevatorSensor.value === 'true';
    return store.dispatch(setGetElevatorSensor(rosHost, val));
  };
  mqtt_ap
    .submit_request(getElevatorSensorParam, getElevatorSensorParamCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  const versionNumberParam = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/flippy_version',
    type: '',
    data: {},
    user: user,
  });
  const versionNumberParamCallback = (versionNumber) => {
    const _versionNumber =
      versionNumber.value != 'None' ? versionNumber : 'unknown';
    return store.dispatch(setVersionNumber(rosHost, _versionNumber));
  };
  mqtt_ap
    .submit_request(versionNumberParam, versionNumberParamCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  getDisabledFryerSensors = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/fryer_slot_occupancy_monitor/disabled_fryer_sensors',
    type: '',
    data: {},
    user: user,
  });

  const disabledFryerSensorsCallback = (disabledFryerSensorsArray) => {
    return store.dispatch(
      setDisabledFryerSensorsArray(rosHost, disabledFryerSensorsArray)
    );
  };
  mqtt_ap
    .submit_request(getDisabledFryerSensors, disabledFryerSensorsCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  getNumFryerSensors = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic:
      '/fryer_slot_occupancy_monitor/fryer_slot_occupancy_monitor/raw_config/slot_detector/fryer/sensor_check/mapping',
    type: '',
    data: {},
    user: user,
  });

  const getNumFryerSensorsCallback = (numFryerSensors) => {
    return store.dispatch(setNumFryerSensors(rosHost, numFryerSensors));
  };
  mqtt_ap
    .submit_request(getNumFryerSensors, getNumFryerSensorsCallback)
    .catch((error) => {
      console.log('Submit_Request error: ' + error);
    });

  rushModeFlag = new MQTTRequest({
    request_type: MQTTRequestType.ROS_PARAM_GET,
    ros_topic: '/install/ui/allow_rush_mode',
    type: '',
    data: {},
    user: user,
  });

  const rushModeFlagCallback = (rushMode) => {
    return store.dispatch(setRushModeFlag(rosHost, rushMode));
  };
  mqtt_ap.submit_request(rushModeFlag, rushModeFlagCallback).catch((error) => {
    console.log('Submit_Request error: ' + error);
  });

  // --- ROS Services ---

  // TODO(ST): The RosIoTCoreBridge Greengrass component
  // currently does not offer an API to return all available
  // services or whether a service is advertised or not. Moving
  // on assuming that the pause command is already advertised
  // before service requests.

  updateTicket[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/smart_chef/update_ticket',
    type: 'smart_chef_msgs/UpdateTicket',
    user: user,
  });

  disableSlot[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/smart_chef/disable_component',
    type: 'smart_chef_msgs/DisableComponent',
    user: user,
  });

  setTargetMode[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/smart_chef/set_target_mode',
    type: 'smart_chef_msgs/SetTargetMode',
    user: user,
  });

  jogElevator[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/elevator/command_service',
    type: 'miso_robot_msgs/ControlElevator',
    user: user,
  });

  requestHelp[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/smart_chef/request_help',
    type: 'smart_chef_msgs/RequestHelp',
    user: user,
  });

  resetElevator[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/elevator/manual_recovery',
    type: 'std_srvs/Trigger',
    user: user,
  });

  robotReboot[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/robot_arm/power_cycle',
    type: 'miso_robot_msgs/PowerCycle',
    user: user,
  });

  robotReset[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/robot_arm/clear_fault_and_resume',
    type: 'miso_robot_msgs/ClearFaultAndResume',
    user: user,
  });
  abortTrajectory[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/robot_arm/abort_trajectory',
    type: 'miso_robot_msgs/AbortTrajectory',
    user: user,
  });
  abortBehavior[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/robot_arm/abort_behavior',
    type: 'miso_robot_msgs/AbortBehavior',
    user: user,
  });
  gogoRoboEff[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/go_to_relative_eff_pos',
    type: 'miso_msgs/GoToRelativeEffPos',
    user: user,
  });
  gogoRoboJoints[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/gogo_robo/arm/joints/command',
    type: 'miso_msgs/GogoRoboJoints',
    user: user,
  });
  goToNamedJoints[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/go_to_named_joints',
    type: 'miso_msgs/srv/GoToNamedJoints',
    user: user,
  });
  fanucPneum[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/fanuc/set_pneumatics_forced',
    type: 'fanuc_msgs/SetPneumaticsForced',
    user: user,
  });

  swapTool[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/swap_tool',
    type: 'tool_manager/SwapTool',
    user: user,
  });
  updateOffset[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/offset_updater/update_offset',
    type: 'miso_msgs/UpdateOffset',
    user: user,
  });
  revertOffset[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/offset_updater/revert_offset',
    type: 'std_srvs/Trigger',
    user: user,
  });

  controlGripperActionConfig[rosHost] = {
    action_topic_prefix: '/robot_arm/control_gripper',
    action_name_prefix: 'miso_robot_msgs/ControlGripperAction',
  };

  getGripperState[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/robot_arm/status_gripper',
    type: 'miso_robot_msgs/StatusGripper',
    user: user,
  });

  toggleFlag[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/smart_chef/toggle_flag',
    type: 'smart_chef_msgs/ToggleFlag',
    user: user,
  });

  runFryerPoseCalibration[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/msc_behaviors',
    type: '/msc_behavior_node',
    user: user,
  });

  disableFryerSensor[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/fryer_slot_occupancy_monitor/set_enable_fryer_sensor',
    type: '/fryer_slot_occupancy_monitor/fryer_slot_occupancy_monitor',
    user: user,
  });

  setRelayController[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/relay_controller/command',
    type: 'miso_robot_msgs/CommandRelay',
    user: user,
  });

  elevatorManual[rosHost] = new MQTTRequest({
    request_type: MQTTRequestType.ROS_SERVICE,
    ros_topic: '/elevator/manual_homing',
    type: 'std_srvs/Trigger',
    user: user,
  });

  await mqtt_ap.wait_for_acks().catch((error) => {
    ack_error = true;
  });
  if (ack_error) {
    // wrapping alerts in settimeout to make them non-blocking
    ack_error = false;
    setTimeout(() => {
      window.alert(
        `Site is not responding. Potential problems\n` +
          `- Internet access may be down.\n` +
          `- Flippy/system is off.\n` +
          `hint: Try power up the system, refresh, or contact Admin.`
      );
    }, 1);
    //await close_ros(rosHost);

    return;
  }
  store.dispatch(setAWSAvailable(rosHost, true));
}
