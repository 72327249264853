import {Dialog, withStyles, Button, Card} from '@material-ui/core';
import React from 'react';
import {colors} from '../styles';
import {IconComponent, callService} from '../util';
import {requestHelp} from '../ros_init';
import {DISABLING_LEVEL} from '../enums';

const styles = (theme) => ({
  container: {
    padding: '2.5rem',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '1.7rem',
  },
  subtitle: {
    padding: '1.5rem 0 1.3rem 0',
    fontSize: '1.25rem',
  },
  subText: {
    fontSize: '1rem',
    paddingBottom: '0.8rem',
  },
  textRed: {
    color: colors.darkRed,
  },
  textGreen: {
    color: colors.green,
  },
  btnDiv: {
    height: '5vh',
    width: '85%',
    margin: '1rem',
    fontSize: '1.5rem',
    borderRadius: '2rem',
  },
  btnConfirm: {
    backgroundColor: colors.tealish,
    color: colors.white,
  },
});

class TroubleshootPopup extends React.Component {
  constructor(props) {
    super();
  }

  handleTroubleshootMode = (user, instance, onClose) => {
    const request = {
      user: {permissions: DISABLING_LEVEL.SUPPORT, name: user},
      state: 'waiting_confirmation',
    };
    callService(
      requestHelp,
      instance,
      request,
      'Waiting For Customer Feedback'
    );
    onClose();
  };
  render() {
    const {classes, open, instance, onClose, needHelp, user} = this.props;
    const {handleTroubleshootMode} = this;

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <Card className={classes.container}>
          <div
            className={classes.title}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <IconComponent icon2="Barrier" width="2vw" color={colors.black} />
              <span style={{marginLeft: '0.5em'}}>Addressed Issue</span>
            </div>
          </div>
          {!needHelp ? (
            <>
              <div className={classes.subtitle}>
                Requesting Troubleshooting Mode will ask the user to <br />
                Close the Barrier and Release the E-Stop.
                <br />
                Once criteria are met, the icon will turn green.
              </div>
              <div
                className={classes.subtitle}
                style={{display: 'flex', alignItems: 'center'}}
              >
                <IconComponent
                  icon2="Barrier"
                  width="1.5vw"
                  color={colors.darkRed}
                />
                <span style={{marginLeft: '0.5em'}}>
                  Troubleshooting Mode Requested.
                </span>
                <span className={classes.textRed} style={{marginLeft: '0.5em'}}>
                  Barrier is Open and/or E-Stop is Engaged
                </span>
              </div>
              <div
                className={classes.subtitle}
                style={{display: 'flex', alignItems: 'center'}}
              >
                <IconComponent
                  icon2="Barrier"
                  width="1.5vw"
                  color={colors.green}
                />
                <span style={{marginLeft: '0.5em'}}>
                  System is ready for Troubleshooting.{' '}
                </span>
                <span
                  className={classes.textGreen}
                  style={{marginLeft: '0.5em'}}
                >
                  Barrier is Closed and E-Stop is Released
                </span>
              </div>
            </>
          ) : null}
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button
              onClick={this.props.onClose}
              variant="outlined"
              className={classes.btnDiv}
            >
              CLOSE
            </Button>
            <Button
              variant="outlined"
              className={`${classes.btnDiv} ${classes.btnConfirm}`}
              onClick={() => handleTroubleshootMode(user, instance, onClose)}
            >
              YES
            </Button>
          </div>
        </Card>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TroubleshootPopup);
