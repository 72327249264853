import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';

import {
  FaExclamationTriangle,
  FaHome,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaLongArrowAltUp,
  FaRedo,
  FaUndo,
  FaArrowLeft,
} from 'react-icons/fa';

import {MQTTRequest, MQTTRequestType} from '../../aws_iot/thing_mqtt_request';
import DraggableDialog from '../../components/DraggableDialog';
import JogButton from '../../components/JogButton';
import MisoDialogTitle from '../../components/MisoDialogTitle';
import {
  USER_ID,
  controlGripperActionConfig,
  fanucPneum,
  goToNamedJoints,
  gogoRoboEff,
  gogoRoboJoints,
  robotReboot,
  robotReset,
  swapTool,
  mqtt_ap,
  jogElevator,
  elevatorManual,
  resetElevator,
  disableSlot,
} from '../../ros_init';
import CollisionModal from '../CollisionModal';
import ConfirmationModal from '../ConfirmationModal';

import backward from '../../assets/backward.svg';
import forward from '../../assets/forward.svg';
import {
  COMMANDS,
  DISABLING_LEVEL,
  GOGO_ROBO_ERROR,
  PNEUM_CHANNEL,
  ALARM_ID,
  ELEVATOR_COMMANDS,
} from '../../enums';
import {callService, IconComponent, subResult} from '../../util';

import {setGripperState} from '../../actions/index';
import store from '../../store/index';

import styles from './styles';

const ToggleLabel = withStyles((theme) => ({
  root: {
    height: '3rem',
    width: '11rem',
    display: 'flex',
  },
  label: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '.7rem',
  },
  selected: {
    backgroundColor: '#00FFCC',
    color: theme.palette.common.black,
  },
}))((props) => {
  const {classes, offText, onText, enabled} = props;

  return (
    <Paper className={classes.root}>
      {!enabled ? (
        <Paper className={classNames(classes.label, classes.selected)}>
          {offText}
        </Paper>
      ) : (
        <div className={classes.label}>{offText}</div>
      )}
      {enabled ? (
        <Paper className={classNames(classes.label, classes.selected)}>
          {onText}
        </Paper>
      ) : (
        <div className={classes.label}>{onText}</div>
      )}
    </Paper>
  );
});

class JogModal extends PureComponent {
  constructor() {
    super();
    this.initialState = {
      gripperSuccess: false,
      newCollisions: [],
      moveParams: {},
      resetting: false,
      retracting: false,
      resetSuccess: false,
      resetFailed: null,
      elevatorResetSuccess: false,
      elevatorResetFailed: null,
      retractSuccess: false,
      rebooting: false,
      rebootSuccess: false,
      moveArm: false,
      rebootDialog: null,
      confirmModalVisible: false,
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    const page = this.props.location.pathname;
    ReactGA.modalview(page + '/jog-modal');
  }
  closeCollision = () => {
    const {callback} = this.state.moveParams;
    if (callback) {
      callback();
    }
    this.setState(this.initialState);
  };
  disableCollision = () => {
    const {newCollisions, moveParams} = this.state;
    const {service, callback} = moveParams;

    this.setState(this.initialState);
    let {request} = moveParams;
    if (request.allowed_collisions) {
      request.allowed_collisions.push(...newCollisions);
    } else {
      request.allowed_collisions = newCollisions;
    }

    this.move(service, request, callback);
  };
  makeJointStates = () => {
    return {
      name: [
        'slider_1',
        'joint_1',
        'joint_2',
        'joint_3',
        'joint_4',
        'joint_5',
        'joint_6',
      ],
      position: [0, 0, 0, 0, 0, 0, 0],
      velocity: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
    };
  };

  handleClick = (event, moveArm) => (cb) => {
    if (moveArm) {
      this.setState({moveArm: true});
    }
    this.clearRobotAlarm(() => {
      this[event](cb);
    });
  };

  // ROBOT MOVEMENTS
  makeMoveRequest = (pos) => {
    return {
      position: pos,
    };
  };
  move = async (service, request, callback, pos) => {
    const {instance} = this.props;
    service[instance].setData = request;
    const serviceCallback = (payload) => {
      const result = payload.data;
      console.log('payload: ', payload);
      if (payload.success) {
        const result_error = payload.data.error;
        const {code, description, metadata} = result_error;
        if (code === GOGO_ROBO_ERROR.IN_COLLISION) {
          const meta_obj = JSON.parse(metadata);
          this.setState({
            newCollisions: meta_obj.collision_pairs,
            moveParams: {service, request, callback},
          });
          // Don't call the callback just yet.
          return;
        } else if (code) {
          window.alert(`Move returned failure! (${instance})\n` + description);
        } else {
          console.log(`Move service result (${instance})`, result);
        }
        if (callback) {
          callback();
        }
        this.setState({moveArm: false});
      } else {
        if (callback) {
          callback();
        }
        this.setState({moveArm: false});
      }
    };
    await mqtt_ap
      .submit_request(service[instance], serviceCallback, true)
      .catch((error) => {
        console.log('Submit_Request error: ' + error);
      });
  };
  moveUp = (callback, args) => {
    const pos = args || {z: 0.01252};
    const request = this.makeMoveRequest(pos);
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Move Up',
    });
    this.move(gogoRoboEff, request, callback, pos);
  };
  moveLeft = (callback) => {
    const pos = {y: 0.025};
    const request = this.makeMoveRequest(pos);
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Move Left',
    });
    this.move(gogoRoboEff, request, callback, pos);
  };
  moveRight = (callback) => {
    const pos = {y: -0.025};
    const request = this.makeMoveRequest(pos);
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Move Right',
    });
    this.move(gogoRoboEff, request, callback, pos);
  };
  rollLeft = (callback) => {
    let joints = this.makeJointStates();
    joints.position[3] = -0.1;
    const request = {
      relative: true,
      joint_states: joints,
    };
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Roll Left',
    });
    this.move(gogoRoboJoints, request, callback);
  };
  rollRight = (callback) => {
    let joints = this.makeJointStates();
    joints.position[3] = 0.1;
    const request = {
      relative: true,
      joint_states: joints,
    };
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Roll Right',
    });
    this.move(gogoRoboJoints, request, callback);
  };
  moveForward = (callback) => {
    const pos = {x: 0.025};
    const request = this.makeMoveRequest(pos);
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Move Forward',
    });
    this.move(gogoRoboEff, request, callback, pos);
  };
  moveBackward = (callback) => {
    const pos = {x: -0.025};
    const request = this.makeMoveRequest(pos);
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Move Backward',
    });
    this.move(gogoRoboEff, request, callback, pos);
  };
  goHome = (callback) => {
    let joints = this.makeJointStates();
    joints.position = this.props.homeJoints;
    const request = {
      relative: false,
      joint_states: joints,
    };
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Go Home',
    });
    this.move(gogoRoboJoints, request, callback);
  };

  // FIXME(JRA): GET BACK HERE.
  goHomeFromAnywhere = (callback) => {
    const request = {
      use_initial_state: false,
      named_joints: COMMANDS.HOME,
      keep_tool: true,
    };
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Go Home From Anywhere',
    });
    this.move(goToNamedJoints, request, callback);
  };

  // FIXME(JRA): GET BACK HERE.
  goToCleanPosition = (callback) => {
    const request = {
      use_initial_state: false,
      named_joints: COMMANDS.CLEAN1,
      keep_tool: true,
    };
    ReactGA.event({
      category: 'Robot Manipulation',
      action: 'Go To Clean Position',
    });
    this.move(goToNamedJoints, request, callback);
  };

  // NOTE(JS): Remove This After all the roshosts are updated to use Generic
  // Robot Interface.
  controlGripper = async (callback) => {
    const {instance, gripperState} = this.props;
    this.setState({toggleGripper: true});

    const successCallback = () => {
      store.dispatch(setGripperState(instance, !gripperState));
      const request = {
        tool_name: '',
      };
      callService(swapTool, instance, request, 'Swap Tool');
    };

    ReactGA.event({
      category: 'Robot Manipulation',
      action: `${gripperState ? 'Close' : 'Open'} Gripper`,
    });

    // subscribing results before publishing the goal
    await subResult(
      instance,
      controlGripperActionConfig[instance],
      'Toggle Gripper',
      callback,
      successCallback
    );

    // now publishing goal
    console.log('Publishing Goal');
    const goal_request = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PUBLISH,
      ros_topic:
        controlGripperActionConfig[instance].action_topic_prefix + '/goal',
      type: controlGripperActionConfig[instance].action_name_prefix + 'Goal',
      data: {goal: {command: gripperState ? 'close' : 'open'}},
      user: USER_ID,
    });
    try {
      await mqtt_ap.submit_request(goal_request);
      this.handleOnCloseClick();
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  rebootClick = (callback) => {
    const {classes} = this.props;
    const onClose = () => {
      this.setState({rebootDialog: null});
      callback();
    };
    const onReboot = () => {
      this.setState({rebootDialog: null});
      this.reboot(callback);
    };
    ReactGA.event({
      category: 'Robot Alarms',
      action: 'Reboot Robot',
    });

    const component = (
      <Dialog open onClose={onClose}>
        <DialogTitle>
          <FaExclamationTriangle className={classes.secondary} />
          {'\tReboot the System'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to REBOOT the robot?
          </DialogContentText>
          <br />
          <DialogContentText>
            Rebooting the robot will take 2-3 minutes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>No, close window</Button>
          <Button onClick={onReboot} color="primary" variant="contained">
            Yes, reboot
          </Button>
        </DialogActions>
      </Dialog>
    );
    this.setState({rebootDialog: component});
  };
  reboot = (callback) => {
    const request = {};
    this.setState({rebooting: true});
    const allCallback = () => {
      this.setState({rebooting: false});
      callback();
    };
    const successCallback = () => {
      this.setState({rebootSuccess: true});
      setTimeout(() => {
        this.setState({rebootSuccess: false});
      }, 1500);
    };

    callService(
      robotReboot,
      this.props.instance,
      request,
      'Reboot Fanuc',
      allCallback,
      successCallback
    );
  };

  clearRobotAlarm = (callback) => {
    const request = {};
    this.setState({resetting: true});
    this.setState({resetFailed: false});
    const allCallback = () => {
      this.setState({resetting: false});
      callback();
    };
    const successCallback = () => {
      this.setState({resetSuccess: true});
      setTimeout(() => {
        this.setState({resetSuccess: false});
      }, 1500);
    };
    const errorCallback = () => {
      this.setState({resetFailed: true});
      setTimeout(() => {
        this.setState({resetFailed: null});
      }, 1500);
    };
    ReactGA.event({
      category: 'Robot Alarms',
      action: 'Clear Alarms',
    });
    callService(
      robotReset,
      this.props.instance,
      request,
      'Reset Alarms',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  handleOnOpenClick = () => {
    this.setState({confirmModalVisible: true});
  };

  handleOnCloseClick = () => {
    this.setState({confirmModalVisible: false});
  };

  moveElevator = async (position) => {
    const {instance} = this.props;
    elevatorManual[instance].setData = {};
    await mqtt_ap.submit_request(elevatorManual[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
    jogElevator[instance].setData = {
      task: position,
    };
    await mqtt_ap.submit_request(jogElevator[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  };

  clearElevatorAlarm = () => {
    const request = {};
    this.setState({resetting: true});
    this.setState({elevatorResetFailed: false});
    const allCallback = () => {
      this.setState({resetting: false});
    };
    const successCallback = () => {
      this.setState({elevatorResetSuccess: true});
      setTimeout(() => {
        this.setState({elevatorResetSuccess: false});
      }, 1500);
    };
    const errorCallback = () => {
      this.setState({elevatorResetFailed: true});
      setTimeout(() => {
        this.setState({elevatorResetFailed: null});
      }, 1500);
    };
    callService(
      resetElevator,
      this.props.instance,
      request,
      'Clear Elevator Alarms',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  disableElevatorAlarm = (disabled) => {
    const request = {
      user: {
        permissions: DISABLING_LEVEL.UI_USER,
        name: DISABLING_LEVEL.UI_USER,
      },
      component_path: '/agent/elevator_dispenser',
      disabled: disabled,
      reason: 'Toggle Disable Elevator',
    };
    callService(
      disableSlot,
      this.props.instance,
      request,
      'Toggle Disable Elevator'
    );
  };

  render() {
    const {
      classes,
      open,
      onClose,
      gripperState,
      jogging,
      notificationState,
      last_mode_user,
      elevatorState,
    } = this.props;
    const {
      newCollisions,
      resetting,
      resetSuccess,
      resetFailed,
      elevatorResetSuccess,
      elevatorResetFailed,
      rebooting,
      rebootSuccess,
      moveArm,
      rebootDialog,
      confirmModalVisible,
    } = this.state;
    let robotAlarms = notificationState.filter(
      (alarm) =>
        alarm.code === 'collision' &&
        alarm.component_path === '/agent/robot_arm'
    );
    let alarmActive = robotAlarms.length > 0;

    let elevatorAlarms = notificationState.filter(
      (alarm) =>
        alarm.code === 'collision' && alarm.component_path === '/agent/elevator'
    );
    let elevatorAlarmActive = elevatorAlarms.length > 0;

    let holdClearMechanismStatus, cycleEStop;
    if (notificationState) {
      holdClearMechanismStatus = Object.keys(notificationState).reduce(
        function (alarm, val) {
          if (notificationState[val].id === ALARM_ID.E_STOPPED)
            alarm[val] = notificationState[val];
          return alarm;
        },
        {}
      );
      cycleEStop = Object.keys(holdClearMechanismStatus).length > 0;
    }

    return (
      <div>
        {jogging && (
          <DraggableDialog
            open={open}
            onClose={onClose}
            paperStyle={{height: '90%', overflow: 'hidden'}}
            aria-labelledby="jog-dialog"
            fullWidth
            maxWidth="md"
          >
            <MisoDialogTitle id="jog-dialog" onClose={onClose}>
              {'Jog Robot'}
            </MisoDialogTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {last_mode_user && (
                <Typography
                  style={{fontSize: '2rem'}}
                  className={classes.redMessage}
                >
                  {last_mode_user} is jogging!
                </Typography>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '2rem',
                }}
              >
                <FaExclamationTriangle
                  className={classes.smallIcon}
                  style={{
                    color: 'red',
                    verticalAlign: 'middle', // Center the icon vertically with the text
                    marginRight: '0.5rem', // Add margin to create space between icon and text
                  }}
                />
                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                  WARNING: FLIPPY IS GOING TO MOVE
                </Typography>
              </div>
              <Typography variant="body1">
                Check cameras and verify that the area is clear and safe for
                system movement before proceeding
              </Typography>
            </div>
            <p className={classes.redMessage}>
              Beware! Collision checking between baskets and the end of the arm
              is disabled during UP, LEFT, and RIGHT jogging.
            </p>
            <DialogContent className={classes.root}>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{textAlign: 'center', fontWeight: 'bold'}}
                >
                  {' '}
                  Robot Arm{' '}
                </Typography>
                <div style={{display: 'flex', gap: '3rem'}}>
                  <div className={classes.columnContainer}>
                    <div className={classes.container}>
                      <div className={classes.buttonContainer}>
                        {/* <div style={{position: 'relative'}}> */}
                        <JogButton
                          label="Move to Home Position"
                          onClick={this.handleClick('goHomeFromAnywhere', true)}
                          disabled={alarmActive || moveArm}
                          classes={{
                            root: classes.smallButtonWide,
                            icon: classes.smallIcon,
                          }}
                        >
                          <FaHome className={classes.mediumIcon} />
                        </JogButton>
                        {/* <JogButton
                        label="Robot Reboot"
                        classes={{
                          root: classes.smallButtonWide,
                          icon: classes.smallIcon,
                        }}
                        onClick={this.handleClick('rebootClick')}
                        success={rebootSuccess}
                      >
                        <FaPowerOff
                          className={classNames(
                            classes.mediumIcon,
                            robotAlarms.length > 0 &&
                              !resetting &&
                              !resetSuccess
                              ? classes.secondary
                              : null
                          )}
                        />
                      </JogButton> */}
                        {/* </div> */}
                      </div>
                      <div className={classes.buttonContainer}>
                        {/* <div style={{position: 'relative'}}> */}
                        <JogButton
                          label="Clear Robot Alarms"
                          classes={{
                            root: classes.smallButtonWide,
                            icon: classes.smallIcon,
                          }}
                          onClick={this.handleClick('clearRobotAlarm')}
                          disabled={
                            rebooting || rebootSuccess || robotAlarms.length > 0
                              ? false
                              : true
                          }
                          color={robotAlarms.length > 0 ? 'secondary' : null}
                          success={resetSuccess}
                          failed={resetFailed}
                        >
                          <FaExclamationTriangle
                            className={classes.smallIcon}
                          />
                        </JogButton>
                        {/* </div> */}
                      </div>
                      <div className={classes.buttonContainer}>
                        {/* <div style={{position: 'relative'}}> */}
                        <JogButton
                          label="Gripper"
                          onClick={this.handleOnOpenClick}
                          disabled={rebooting}
                          classes={{
                            root: classes.smallButtonWide,
                            icon: classes.smallIcon,
                          }}
                          disableInProgressProcess
                        >
                          <ToggleLabel
                            offText="closed"
                            onText="opened"
                            enabled={gripperState}
                          />
                        </JogButton>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className={classes.container}>
                      <div style={{position: 'relative'}}>
                        <div style={{paddingBottom: '1rem'}}>
                          <JogButton
                            label='Move up 0.5"'
                            onClick={this.handleClick('moveUp', true)}
                            disabled={alarmActive || moveArm}
                          >
                            <FaLongArrowAltUp className={classes.icon} />
                          </JogButton>
                        </div>
                        <div className={classes.buttonContainer}>
                          <div className={classes.smallButtonContainer}>
                            <JogButton
                              label="Backward"
                              classes={{root: classes.smallButton}}
                              onClick={this.handleClick('moveBackward', true)}
                              disabled={alarmActive || moveArm}
                            >
                              <img
                                className={classes.smallImgIcon}
                                src={backward}
                                alt="backward"
                              />
                            </JogButton>
                            <JogButton
                              label="Forward"
                              classes={{root: classes.smallButton}}
                              onClick={this.handleClick('moveForward', true)}
                              disabled={alarmActive || moveArm}
                            >
                              <img
                                className={classes.smallImgIcon}
                                src={forward}
                                alt="forward"
                              />
                            </JogButton>
                          </div>
                          <div className={classes.smallButtonContainer}>
                            <JogButton
                              label="Left"
                              classes={{root: classes.smallButton}}
                              onClick={this.handleClick('moveLeft', true)}
                              disabled={alarmActive || moveArm}
                            >
                              <FaLongArrowAltLeft
                                className={classes.smallIcon}
                              />
                            </JogButton>
                            <JogButton
                              label="Right"
                              classes={{root: classes.smallButton}}
                              onClick={this.handleClick('moveRight', true)}
                              disabled={alarmActive || moveArm}
                            >
                              <FaLongArrowAltRight
                                className={classes.smallIcon}
                              />
                            </JogButton>
                          </div>
                          <div className={classes.smallButtonContainer}>
                            <JogButton
                              label="Roll Left"
                              classes={{
                                root: classes.smallButton,
                                label: classes.smallButtonLabel,
                              }}
                              onClick={this.handleClick('rollLeft', true)}
                              disabled={alarmActive || moveArm}
                            >
                              <FaUndo className={classes.smallIcon} />
                            </JogButton>
                            <JogButton
                              label="Roll Right"
                              classes={{
                                root: classes.smallButton,
                                label: classes.smallButtonLabel,
                              }}
                              onClick={this.handleClick('rollRight', true)}
                              disabled={alarmActive || moveArm}
                            >
                              <FaRedo className={classes.smallIcon} />
                            </JogButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  classes.container,
                  classes.rightContainer
                )}
              >
                <div>
                  <Typography
                    variant="h5"
                    style={{textAlign: 'center', fontWeight: 'bold'}}
                  >
                    {' '}
                    Elevator{' '}
                  </Typography>
                  <div style={{position: 'relative'}}>
                    <JogButton
                      label="Send to Dump Position"
                      classes={{
                        root: classes.smallButtonWide,
                      }}
                      onClick={() => {
                        this.moveElevator(ELEVATOR_COMMANDS.DUMP);
                      }}
                      disabled={
                        alarmActive || elevatorState.disabled ? true : false
                      }
                      color={null}
                    ></JogButton>
                    <JogButton
                      label="Send to Home Position"
                      classes={{
                        root: classes.smallButtonWide,
                      }}
                      onClick={() => {
                        this.moveElevator(ELEVATOR_COMMANDS.HOME);
                      }}
                      disabled={
                        alarmActive || elevatorState.disabled ? true : false
                      }
                      color={null}
                    ></JogButton>
                    <JogButton
                      label="Send to Receive Food Position"
                      classes={{
                        root: classes.smallButtonWide,
                      }}
                      onClick={() => {
                        this.moveElevator(ELEVATOR_COMMANDS.RECEIVE_FOOD);
                      }}
                      disabled={
                        alarmActive || elevatorState.disabled ? true : false
                      }
                      color={null}
                    ></JogButton>
                    <JogButton
                      label="Clear Elevator Alarms"
                      classes={{
                        root: classes.smallButtonWide,
                      }}
                      onClick={this.clearElevatorAlarm}
                      disabled={
                        rebooting || rebootSuccess || elevatorAlarmActive
                          ? false
                          : true
                      }
                      color={elevatorAlarmActive ? 'secondary' : null}
                      success={elevatorResetSuccess}
                      failed={elevatorResetFailed}
                    ></JogButton>
                    <JogButton
                      label={
                        elevatorState.disabled
                          ? 'Enable Elevator'
                          : 'Disable Elevator'
                      }
                      classes={{
                        root: classes.smallButtonWide,
                      }}
                      onClick={() =>
                        this.disableElevatorAlarm(
                          elevatorState.disabled ? false : true
                        )
                      }
                      color={null}
                    ></JogButton>
                  </div>
                </div>
              </div>
            </DialogContent>
          </DraggableDialog>
        )}
        <CollisionModal
          collisions={newCollisions}
          onClose={this.closeCollision}
          disableCallback={this.disableCollision}
        />
        <ConfirmationModal
          open={confirmModalVisible}
          content={
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <FaExclamationTriangle
                    className={classes.smallIcon}
                    style={{color: 'red'}}
                  />
                  <Typography variant="h5" style={{fontWeight: 'bold'}}>
                    WARNING: FLIPPY MAY DROP BASKET
                  </Typography>
                </div>
                <Typography variant="subtitle1">
                  Opening the Gripper may cause the basket to fall onto the
                  floor, or into the hot fryer.
                </Typography>
                <Typography variant="subtitle1">
                  Check cameras and verify that the area is safe and clear, and
                  the fryer barrier is closed before proceeding.
                </Typography>
              </div>
            </>
          }
          onClickNegativeJog={this.controlGripper}
          rebooting={rebooting}
          onClickPositive={this.handleOnCloseClick}
          textNegative={
            <>
              <div>
                <FaExclamationTriangle
                  className={classes.buttonSmallIcon}
                  style={{color: 'white', padding: '0em'}}
                />
                <Typography
                  variant="body1"
                  style={{fontWeight: 'bold', color: 'white'}}
                >
                  CONTINUE TO {gripperState ? 'Close' : 'Open'} Gripper
                </Typography>
              </div>
            </>
          }
          textPositive={
            <div>
              <FaArrowLeft
                className={classes.buttonSmallIcon}
                style={{padding: '0em'}}
              />
              <Typography variant="body1" style={{fontWeight: 'bold'}}>
                GO BACK
              </Typography>
            </div>
          }
          containerStyle={classes.containerStyle}
          positiveBtnStyle={classes.positiveBtnStyle}
          negativeBtnStyle={{
            root: classes.negativeRootBtnStyle,
            icon: classes.negativeIconBtnStyle,
          }}
        />

        {rebootDialog}
      </div>
    );
  }
}

JogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  gripperState: PropTypes.bool.isRequired,
  jogging: PropTypes.bool.isRequired,
  homeJoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  location: PropTypes.object,
};

export default withStyles(styles)(withRouter(JogModal));
